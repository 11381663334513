import React from 'react';
import styled from 'styled-components';
import CustomIcon from './CustomIcon';
import { Subtitle5 } from './Typography';

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  min-width: 300px;
  transition: 0.4s;
  padding: 3rem 0;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 10px;
  color: #13273f;
  border: 1px solid #dbe3eb;
  align-items: center;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 3px 40px rgba(108, 108, 138, 0.2);
    color: #2cd19e;
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;
const Title = styled(Subtitle5)`
  margin-top: 1rem;
  color: inherit;
`;
interface SolutionComponentProps {
  imageSrc: string;
  label: string;
}
const HelpCenterCard: React.FunctionComponent<SolutionComponentProps> = ({
  imageSrc,
  label,
}) => (
  <Wrapper href={`#${label.replaceAll(` `, `_`)}_FAQ`}>
    <CustomIcon src={imageSrc} />
    <Title>{label}</Title>
  </Wrapper>
);

export default HelpCenterCard;
